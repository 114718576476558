// @file Global confirmation dialog store
import { OzConfirmationDialogBoxButtonScheme } from '@@/library/v4/components/OzConfirmationDialogBox.vue'
import type { Wall, WallCamelCase } from '@@/types'
import { defineStore } from 'pinia'
import { ref } from 'vue'

export enum ConfirmationDialogLayoutVariant {
  Small = 'Small',
  Large = 'Large',
  Drawer = 'Drawer',
}

export interface ConfirmationDialogPayload {
  title: string
  subject?: null | { wall: Wall | WallCamelCase }
  layoutVariant?: ConfirmationDialogLayoutVariant
  iconSrc?: string | null
  iconAlt?: string | null
  body?: string
  customBodyClasses?: string
  confirmButtonText?: string
  discardButtonText?: string
  cancelButtonText?: string
  afterConfirmActions: Array<() => void>
  afterDiscardActions?: Array<() => void>
  afterCancelActions?: Array<() => void>
  isCodeProtected?: boolean
  buttonScheme?: OzConfirmationDialogBoxButtonScheme
  forceFullWidthButtons?: boolean
  xShadow?: boolean
  shouldFadeIn?: boolean
}

const defaultConfirmationDialogData: ConfirmationDialogPayload = {
  layoutVariant: ConfirmationDialogLayoutVariant.Small,
  subject: null,
  iconSrc: null,
  iconAlt: null,
  title: '',
  body: '',
  customBodyClasses: '',
  confirmButtonText: '',
  discardButtonText: '',
  cancelButtonText: '',
  afterConfirmActions: [],
  afterDiscardActions: [],
  afterCancelActions: [],
  isCodeProtected: false,
  buttonScheme: OzConfirmationDialogBoxButtonScheme.Default,
  forceFullWidthButtons: false,
  xShadow: false,
  shouldFadeIn: true,
}

export const useGlobalConfirmationDialogStore = defineStore('globalConfirmationDialog', () => {
  const isOpen = ref<boolean>(false)
  const confirmationDialogData = ref<ConfirmationDialogPayload>(defaultConfirmationDialogData)

  const openConfirmationDialog = (payload: ConfirmationDialogPayload): void => {
    isOpen.value = true
    confirmationDialogData.value = Object.assign({}, confirmationDialogData.value, payload)
  }

  const resetConfirmationDialog = (): void => {
    isOpen.value = false
    confirmationDialogData.value = Object.assign({}, confirmationDialogData.value, defaultConfirmationDialogData)
  }

  const executeActions = (actions?: Array<() => void>): void => {
    if (actions === undefined) return

    actions.forEach((action): void => {
      if (typeof action === 'function') {
        action()
      }
    })
  }

  const confirm = (): void => {
    executeActions(confirmationDialogData.value?.afterConfirmActions)
    resetConfirmationDialog()
  }

  const cancel = (): void => {
    executeActions(confirmationDialogData.value?.afterCancelActions)
    resetConfirmationDialog()
  }

  const discard = (): void => {
    executeActions(confirmationDialogData.value?.afterDiscardActions)
    resetConfirmationDialog()
  }

  return {
    // State
    isOpen,
    confirmationDialogData,

    // Actions
    openConfirmationDialog,
    confirm,
    cancel,
    discard,
  }
})

export { OzConfirmationDialogBoxButtonScheme }
